/* eslint-disable camelcase */

import { emailRegex, lastNameRegex, nameRegex, usPhoneRegex } from '../../utils/regex'
import { ContactAction } from '../../store/actions/ContactAction'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import HubspotContactForm from '../HubSpot/HubspotContactForm'
import { phoneFormatter } from '../ContactUs/ContactSection'
import TextArea from 'antd/es/input/TextArea'
import { useDispatch } from 'react-redux'
import React, { useState } from 'react'
import { Button, Input } from 'antd'
import InnerPage from '../InnerPage'

export default function Feedback() {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  })
  const dispatch = useDispatch()


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('your_action')
    dispatch(ContactAction({ ...formData, recaptcha_token: token }, setFormData) as any)
  }

  const handleImageLoad = () => {
    setIsImageLoaded(true)
  }

  return (
    <>
      <InnerPage>
        <div className='feedback'>
          <div className='left'>
            {!isImageLoaded && <div className='placeholder'></div>}
            <img
              src={require('../../assets/images/newHome/feedback.png')}
              alt=''
              onLoad={handleImageLoad}
              style={{ display: isImageLoaded ? 'block' : 'none' }}
            />
          </div>
          <div className='right'>
            
            <div className='logo'>
              <img src={require('../../assets/images/newHome/feedback-logo.svg').default} alt='' />
            </div>
            <div className='heading'>
              <h1>We’d love to hear from you!</h1>
            </div>
            <HubspotContactForm />
         
            {/* <form onSubmit={handleSubmit}>
              <div className='input-main'>
                <div className='input-field'>
                  <label>First name</label>
                  <Input
                    type='text'
                    value={formData.firstName}
                    required
                    pattern={nameRegex.source}
                    placeholder='First name'
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, firstName: e.target.value }))
                    }
                  />
                </div>
                <div className='input-field'>
                  <label>Last name</label>
                  <Input
                    type='text'
                    required
                    value={formData.lastName}
                    pattern={lastNameRegex.source}
                    placeholder='Last name'
                    onChange={(e) => setFormData((prev) => ({ ...prev, lastName: e.target.value }))}
                  />
                </div>
              </div>
              <div className='input-main'>
                <div className='input-field'>
                  <label>Email Address</label>
                  <Input
                    type='email'
                    required
                    value={formData.email}
                    pattern={emailRegex.source}
                    placeholder='jamessmith@gmail.com'
                    onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                  />
                </div>
                <div className='input-field'>
                  <label>Phone Number</label>
                  <Input
                    type='text'
                    required
                    value={formData.phoneNumber}
                    pattern={usPhoneRegex.source}
                    placeholder='+1 (555) 991-1243'
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        phoneNumber: phoneFormatter(e.target.value),
                      }))
                    }
                  />
                </div>
              </div>
              <div className='message-section'>
                <label>Message</label>
                <TextArea
                  placeholder='Your Message'
                  rows={5}
                  value={formData.message}
                  onChange={(e) => setFormData((prev) => ({ ...prev, message: e.target.value }))}
                />
              </div>
              <div className='send-button'>
                <Button htmlType='submit'>Send message</Button>
              </div>
            </form> */}
          </div>
        </div>
      </InnerPage>
    </>
  )
}
