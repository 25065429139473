import { GlobalRouteInterface } from './interface'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HTTP } from '../../utils/http'

const GlobalRoute: FC<GlobalRouteInterface> = ({ children }) => {
  const navigate = useNavigate()
  const [authStatus, setAuthStatus] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const token = await HTTP.refreshToken()
        if (token) {
          setAuthStatus(true)
        } else {
          setTimeout(() => {
            navigate('/sign-in')
          }, 500)
        }
      } catch {
        setAuthStatus(false)
        setTimeout(() => {
          navigate('/sign-in')
        }, 500)
      }
    })()
  }, [])

  if (authStatus) return children
  else {
    return null
  }
}
export default GlobalRoute
