import { LandingPageBody, PromoBody } from './interfaces'
import { HTTP } from '../../utils/http'
import { BUSINESS } from '../constants'

function fileToBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = (error) => reject(error)
  })
}

export const getBusinessProfile = (businessId: string) => async (dispatch: any) => {
  console.log(businessId)
  try {
    dispatch({ type: BUSINESS.PENDING })
    const response = await HTTP.get('api/business', { businessId: businessId })
    dispatch({ type: BUSINESS.SUCCESS, payload: response?.data })
  } catch (error: any) {
    dispatch({ type: BUSINESS.ERROR, error: error })
  }
}

export const createThroughPromoCode = (body: PromoBody) => async (dispatch: any) => {
  try {
    dispatch({ type: BUSINESS.PENDING })
    const response = await HTTP.put('api/business', body)
    dispatch({ type: BUSINESS.SUCCESS, payload: response?.data })
  } catch (error: any) {
    dispatch({ type: BUSINESS.ERROR, error: error })
  }
}

export const createThroughPageGenerator = (body: LandingPageBody) => async (dispatch: any) => {
  const data = { ...body }
  try {
    if (data?.logo) {
      const base64Image = await fileToBase64(data.logo)
      data.logo = base64Image
    }
    dispatch({ type: BUSINESS.PENDING })
    const response = await HTTP.post('api/business', data)
    dispatch({ type: BUSINESS.SUCCESS, payload: response?.data })
  } catch (error: any) {
    dispatch({ type: BUSINESS.ERROR, error: error })
  }
}
