/* eslint-disable camelcase */
import NoResultData from '../Profile/SearchResults/ProfileNoPending/NoResultData'
import { resultListReducerData } from '../../store/reducers/interfaces'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ResultTableIsLogedinInterface } from './interfaces'
import { FC, useState, useEffect } from 'react'
import RecordsTable from './RecordsTable'
import { Button } from 'antd'

import './styles.scss'

const ResultTableIsLogedin: FC<ResultTableIsLogedinInterface> = ({
  resultListData,
  // resultRemoveAction,
  // resultRemoveStatus,
  total,
}) => {
  const [newDataState, setNewDataState] = useState<resultListReducerData['data']['results']>([])
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  let webLogos: any[] = []
  const data = localStorage.getItem('webLogos')
  
  if (data !== 'undefined') {
    // console.log(data)
    webLogos = JSON.parse(data as string)
  }

  useEffect(() => {
    if (resultListData?.data?.results) {
      const newState = [...resultListData.data.results]
      for (const [idx, item] of resultListData.data.results.entries()) {
        for (const data of Object.values(item.details)) {
          if (data.length === 1) {
            newState.push(item)
            newState.splice(idx, 1)
          }
        }
      }
      setNewDataState(newState)
    }
  }, [resultListData?.data?.results])

  // const handleRemoveClick = (url: string) => {
  //   setIsState(url)
  //   const body = {
  //     profileUrl: url,
  //   }
  //   resultRemoveAction(body)
  // }

  if (!total.record) return <NoResultData isNull={false} />

  return (
    <>
      <div className='container result-section '>
        <div className='result-table result-custom-table result-custom-table result-custom-section'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingInlineEnd: '22px',
            }}
          >
            <div className=''>
              <div className='logo-box'>
                <img src={require('../../assets/images/result/check.svg').default} />
                <h6>
                  {searchParams.get('firstName')} {searchParams.get('lastName')}
                </h6>
              </div>
              <p className='text-found'>
                Found {total.record} people in <span>{total.cities.slice(0, 3).join(', ')}</span>{' '}
                and {total.cities.length - 3} other states
              </p>
              <p className='info-text'>*Removal could take 10 days to 2 months</p>
            </div>
            <Button onClick={() => navigate('/sign-in')}>
              <span className='mx-auto'>Register Now</span>
            </Button>
          </div>
          <div className='top-box'>
            <p>Website</p>
            <p>URL</p>
            <p></p>
          </div>
          <div className='sticky-section'>
            <div className='data-section'>
              {newDataState.map(({ details, website,updated_at }, index) => (
                <div key={index}>
                  <RecordsTable
                    details={details as any}
                    website={website}
                    setIsActive={'djkb' as any}
                    isActive={[2]}
                    webLogos={webLogos}
                    updated_at={updated_at}
                  />
                </div>
              ))}
            </div>
            {/* <div className='yourself-section'>
              <div className='yourself-box'>
                <div className='img-section'>
                  <div className='img-box'>
                    <img src={require('../../assets/images/home/Ghost/first.svg').default} />
                  </div>
                  <h1>Ghost Yourself</h1>
                  <div className='img-box-second'>
                    <img src={require('../../assets/images/home/Ghost/second.svg').default} />
                  </div>
                </div>
                <p>
                  Register and we will ensure your disappearance from the listed broker sites Every
                  Month.
                </p>
                <div className=''>
                  <div className='img-box-third'>
                    <img src={require('../../assets/images/home/Ghost/third.svg').default} />
                  </div>
                  <div className='img-box-third-fourth'>
                    <img src={require('../../assets/images/home/Ghost/fourth.svg').default} />
                  </div>
                </div>
                <button
                  onClick={() =>
                    navigate(
                      `/sign-up?firstName=${searchParams.get(
                        'firstName',
                      )}&lastName=${searchParams.get('lastName')}&city=${searchParams.get(
                        'city',
                      )}&state=${searchParams.get('state')}`,
                    )
                  }
                >
                  Register Now
                </button>
              </div>
            </div> */}
          </div>
          {/* <div className='global-box'>
            <div className='check-state'>
              <Checkbox className='scales-checkbox' />
              <div className='info-state'>
                <img
                  className='vector'
                  src={require('../../assets/images/result/logo-main 1.svg').default}
                />
                <p>www.radaris.com</p>
              </div>
            </div>
            <div className='all-section'>
              <div className='middle-box'>
                <span className='name-state'>James Smith</span>
                <p>
                  Age:<span>50</span>
                </p>
                <p>
                  Location:<span>Marina Del Rey, CA, Los Angeles</span>
                </p>
              </div>
              <div className='button-box'>
                <Button>Remove</Button>
                <Button>Keep</Button>
                <Button>Hide</Button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ResultTableIsLogedin
