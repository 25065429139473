import { toast } from 'react-toastify'

import { ResultBody, ResultRemoveBody } from './interfaces'
import { RESULT_LIST, RESULT_REMOVE } from '../constants'
import { HTTP } from '../../utils/http'

export const ResultList = (body: ResultBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: RESULT_LIST.PENDING })
    const response = await HTTP.post('api/data/search-status', body)
    dispatch({ type: RESULT_LIST.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: RESULT_LIST.ERROR, payload: error })
  }
}

export const ResultListReset = () => ({
  type: RESULT_LIST.RESET,
})

export const ResultRemoveAction = (body: ResultRemoveBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: RESULT_REMOVE.PENDING })
      const response = await HTTP.post('api/data/remove-profile', { profileUrl: body.profileUrl })
      dispatch({
        type: RESULT_REMOVE.SUCCESS,
        payload: { ...response.data, profileUrl: body.profileUrl, website: body.website },
        RESULT_REMOVE,
      })
    } catch (error: any) {
      // toast.error(error?.response?.data?.error || error.message, { toastId: body.profileUrl })
      dispatch({ type: RESULT_REMOVE.ERROR ,payload:{
        profileUrl: body.profileUrl, website: body.website
      } ,
      RESULT_REMOVE,

    })
    }
  }
