import React, { FC } from 'react'
import { Button, Modal } from 'antd'
import { DeleteModalInterface } from './interfaces'
import Spinner from '../../../../core-ui/Spinner'

const DeleteModal: FC<DeleteModalInterface> = ({
  accountDeleteStatus,
  accountDeleteAction,
  onClose,
  open,
}) => {
  return (
    <Modal
      wrapClassName='profile-delete-modal'
      open={open}
      centered
      footer={null}
      onCancel={onClose}
      // closeIcon={<img src={require('../../../assets/images/em-games/modal-close.svg').default} />}
    >
      <div className='delete-part'>
        <h1>
          You are about to delete your account and erase all your data from our servers, do you want
          to continue?
        </h1>
        <div className='account account-canceled'>
          {' '}
          <div className='button-box'>
            <Button onClick={() => accountDeleteAction()}>
              {accountDeleteStatus === 'pending' ? <Spinner /> : 'Yes, Delete my account'}
            </Button>
            <Button onClick={() => onClose()}>No, Cancel</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
