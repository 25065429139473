import { nameRegex } from '../../../utils/regex'
import Spinner from '../../../core-ui/Spinner'
import { useSelector } from 'react-redux'
import { FormProps } from './interface'
import { useState } from 'react'

import './styles.scss'

const PromoCodeForm = ({ formData, handleChange, handleSubmit }: FormProps) => {
  const { businessStatus, error, success } = useSelector((state: any) => state.BusinessReducer)
  const [formValidation, setFormValidation] = useState({
    companyName: true,
    websiteUrl: true,
    pocName: true,
    pocEmailAddress: true,
    pocTitle: true,
    pocPhone: true,
    estimateUsers: true,
  })

  return (
    <div>
      <form onSubmit={handleSubmit} className='form-validation'>
        <div
          style={{
            marginBottom: '20px',
          }}
        >
          <label htmlFor='companyName'>
            Company Name<span>*</span>
          </label>
          <input
            data-validate={formValidation.companyName.toString()}
            type='text'
            id='companyName'
            name='companyName'
            placeholder='Company Name'
            value={formData.companyName}
            pattern={nameRegex.source}
            onChange={handleChange}
            required
            onBlur={(e) =>
              setFormValidation((prev: any) => {
                const update = { ...prev }
                update.companyName = e.target.checkValidity()
                return update
              })
            }
            onFocus={(e) =>
              setFormValidation((prev: any) => {
                const update = { ...prev }
                update.companyName = true
                return update
              })
            }
          />
           <span>*Company name must be at least 3 characters long.</span>
        </div>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1', marginRight: '10px' }}>
            <label htmlFor='websiteURL'>
              Website URL<span>*</span>
            </label>
            <input
              data-validate={formValidation.websiteUrl.toString()}
              type='url'
              id='websiteURL'
              name='websiteUrl'
              placeholder='www.companyname.com'
              value={formData.websiteUrl}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.websiteUrl = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.websiteUrl = true
                  return update
                })
              }
            />
              <span>*Enter a valid url</span>
          </div>
          <div style={{ flex: '1' }}>
            <label htmlFor='poc'>
              POC Name<span>*</span>
            </label>
            <input
              data-validate={formValidation.pocName.toString()}
              type='text'
              id='poc'
              name='pocName'
              placeholder='Ex. John'
              value={formData.pocName}
              onChange={handleChange}
              required
              pattern={nameRegex.source}
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocName = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocName = true
                  return update
                })
              }
            />
             <span>*Poc Name must be at least 3 characters long.</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1', marginRight: '10px' }}>
            <label htmlFor='pocEmail'>
              POC Email Address<span>*</span>
            </label>
            <input
              data-validate={formValidation.pocEmailAddress.toString()}
              type='email'
              id='pocEmail'
              name='pocEmailAddress'
              placeholder='example@gmail.com'
              pattern='^[\w\.\+\-]+@[\w\-]+\.[a-zA-Z]{2,}$'
              value={formData.pocEmailAddress}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocEmailAddress = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocEmailAddress = true
                  return update
                })
              }
            />
            <span>*Enter a valid poc email address.</span>
          </div>
          <div style={{ flex: '1' }}>
            <label htmlFor='pocTitle'>
              POC Title<span>*</span>
            </label>
            <input
              data-validate={formValidation.pocTitle.toString()}
              type='text'
              id='pocTitle'
              name='pocTitle'
              placeholder='Ex. Company Name'
              value={formData.pocTitle}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocTitle = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocTitle = true
                  return update
                })
              }
              pattern={nameRegex.source}
            />
            <span>*Poc title must be at least 3 characters long.</span>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <div style={{ flex: '1', marginRight: '10px' }}>
            <label htmlFor='pocPhone'>
              POC Phone<span>*</span>
            </label>
            <input
              data-validate={formValidation.pocPhone.toString()}
              type='text'
              id='pocPhone'
              name='pocPhone'
              placeholder='Ex +1 021 225641'
              value={formData.pocPhone}
              onChange={handleChange}
              pattern='^\(\d{3}\) \d{3}-\d{4}$'
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocPhone = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocPhone = true
                  return update
                })
              }
            />
            <span>*Enter a valid phone number</span>
          </div>
          <div style={{ flex: '1' }}>
            <label htmlFor='estimateUsers'>
              Estimated Users<span>*</span>
            </label>
            <input
              data-validate={formValidation.estimateUsers.toString()}
              type='number'
              id='estimateUsers'
              name='estimateUsers'
              placeholder='e.g, 8'
              value={formData.estimateUsers}
              onChange={handleChange}
              required
              min={1}
              max={8}
              onBlur={(e) => {
                console.log(e.target.checkValidity())
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.estimateUsers = e.target.checkValidity()
                  return update
                })
              }}
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.estimateUsers = true
                  return update
                })
              }
            />
           <span>*Number of users must be between 1 and 8.</span>
          </div>
        </div>
        {error && <span className='text-danger'>*{error || ''}</span>}
        {success && <span className='text-success'>*{success}</span>}
        <div style={{ textAlign: 'right' }}>
          <button type='submit' style={{ padding: '10px 20px' }}>
            {businessStatus === 'pending' ? <Spinner /> : 'Generate'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default PromoCodeForm
