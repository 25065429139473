
const Details = () => {
  return (
    <div className='search-section'>
      <div className='container-fluid'>
        <h1>
          Your search is <span>Anonymous</span> and <span>Private</span>
        </h1>

        <div className='details'>
          <div className='details-box'>
            <img src={require('../../assets/images/newHome/details.svg').default} />
            <h1>Enter Details</h1>
            <p>We collect your data & encrypt your identity</p>
          </div>
          <img
            className='vector'
            src={require('../../assets/images/home/vector-first.svg').default}
          />
          <div className='details-box'>
            <img src={require('../../assets/images/newHome/analyze.svg').default} />
            <h1>We Analyze</h1>
            <p>We will search over 80 data broker sites</p>
          </div>
          <img
            className='vector'
            src={require('../../assets/images/home/vector-second.svg').default}
          />
          <div className='details-box'>
            <img src={require('../../assets/images/newHome/results.svg').default} />
            <h1>Results</h1>
            <p>All sites that have your information</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
