import { BoxInterface } from './interface'

export default function Box({ issueImage, nameName }: BoxInterface) {
  return (
    <div>
      <img src={issueImage} alt={nameName} />
      <span>{nameName}</span>
    </div>
  )
}
