import { getBusinessProfile } from '../../../store/actions/BusinessAction'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import InnerPage from '../../InnerPage'
import { useEffect } from 'react'

import './styles.scss'

const index = () => {
  const { businessData } = useSelector((state: any) => state.BusinessReducer)
  const { id = '' } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBusinessProfile(id) as any)
  }, [])

  return (
    <div>
      <InnerPage>
        <div className='d-flex justify-content-center'>
          <div className='website-preview-page'>
            <div className='image-content'>
              <div className='image'>
                {businessData?.logoUrl && (
                  <img src={businessData?.logoUrl} alt='' height={'100%'} />
                )}
              </div>
              <div className='content'>
                <h5>
                  Hackfree services for <span>{businessData?.companyName}</span>
                </h5>
                <p>
                  We &apos;re delighted to offer an exclusive rate to CyberGuy affiliates. Benefit
                  from continuous monitoring and automated removal across <span>80 platforms</span>.
                </p>
              </div>
            </div>
            <div className='packages'>
              <div className='packages-price'>
                <div className='container'>
                  <div className='packages-price-container'>
                    <div className='package-block'>
                      <div>
                        <div className='price'>
                          <h5>$72</h5>
                          <p>
                            <del>$100</del>
                          </p>
                        </div>
                      </div>
                      <div className='save-money'>
                        <p>save 25%</p>
                      </div>
                    </div>
                    <p>Person per year</p>
                  </div>
                  <div className='packages-price-container'>
                    <div className='package-block'>
                      <div>
                        <div className='price'>
                          <h5>$72</h5>
                          <p>
                            <del>$100</del>
                          </p>
                        </div>
                      </div>
                      <div className='save-money'>
                        <p>save 25%</p>
                      </div>
                    </div>
                    <p>Family per year(Covers up to 6 adults)</p>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    <button type='submit' style={{background:businessData.buttonBackgroundColor,color:businessData.buttonTextColor}}>Continue</button>
                  </div>
                </div>
              </div>
              <div className='package-content'>
                <div className='content-block'>
                  <h6>Hackfree Overview for CyberGuy</h6>
                  <a href='#'>Click here to view</a>
                </div>
                <div className='content-block'>
                  <h6>Your Account</h6>
                  <p>
                    Please register using your professional email address to access the special
                    rate. Your subscription covers a full year with pre-payment, and you&apos;ll
                    receive notifications when it&apos;s time for auto-renewal.
                  </p>
                </div>
                <div className='content-block'>
                  <h6>We Work to Protect Your Privacy and Identity</h6>
                  <p>
                    If you&apos;re not entirely satisfied, please reach out to us, and we&apos;ll
                    strive to find a solution that meets your needs.
                  </p>
                </div>
                <div className='content-block'>
                  <h6>Questions?</h6>
                  <p>Contact CyberGuy.</p>
                  {(businessData?.pocName || businessData?.pocEmailAddress) && (
                    <p>
                      {businessData?.pocName}, <span>{businessData?.pocEmailAddress}</span>
                    </p>
                  )}
                </div>
                <div className='content-block'>
                  <h6>Help with your account</h6>
                  <p>Contact your Hackfree account manager:</p>
                  <p>
                    <span>{businessData?.websiteUrl}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InnerPage>
    </div>
  )
}

export default index
