import privateImage from '../../assets/images/newHome/private.png'
import publicImage from '../../assets/images/newHome/public.png'
import decision from '../../assets/images/newHome/decision.png'
import provider from '../../assets/images/newHome/provider.png'
import WhoNeedStep from './WhoNeedStep'

export default function WhoNeed() {
  return (
    <div className='search-section'>
      <div className='container-fluid'>
        <h1>Who needs Hackfree the most?</h1>

        <div className='steps'>
          <WhoNeedStep
            heading='Private Sector'
            image={privateImage}
            paragraph='High-profile executives, sales teams, customer service reps, or anyone who interacts with the public.'
          />
          <WhoNeedStep
            heading='Decision Makers'
            image={decision}
            paragraph='Jobs that make crucial decisions affecting lives–think healthcare and legal professions.'
          />
          <WhoNeedStep
            heading='Public Sector'
            image={publicImage}
            paragraph='Ideal for law enforcement and social services departments.'
          />
          <WhoNeedStep
            heading='Providers'
            image={provider}
            paragraph='Cybersecurity, IDP, Credit, and Fintech companies: integrate our API to offer enhanced protection and peace of mind.'
          />
        </div>
      </div>
    </div>
  )
}
