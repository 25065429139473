/* eslint-disable camelcase */

import { Button, Input, Select, DatePicker, DatePickerProps } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/en'

import { cityRegex, emailRegex, lastNameRegex, middleNameRegex, nameRegex, usPhoneRegex } from '../../../../utils/regex'
import { ProfileEditInfo } from '../../../../store/actions/ProfileAction'
import { NewProfileEditSectionInterface } from '../ProfileView/interface'
import { DeleteProfileBody } from '../../../../store/actions/interfaces'
import { phoneFormatter } from '../../../ContactUs/ContactSection'
import Spinner from '../../../../core-ui/Spinner'
import state from '../../../../assets/state'

import './editSectionNew.scss'
import './editSection.scss'

export type KeyType = 'firstName' | 'middleName' | 'lastName' | 'phoneNumber' | 'city' | 'email'

const NewProfileEdit: FC<NewProfileEditSectionInterface> = ({
  profileEditInfoAction,
  profileEditStatus,
  profileEditData,
  profileInfoNoPendingData,
  setEditProfileState,
}) => {
  const [dateOfBirth, setDateOfBirth] = useState<string | string[]>(profileInfoNoPendingData?.data?.birthDate || '')
  const [selectState, setSelectState] = useState( profileInfoNoPendingData?.data?.state || [{ value: '' }] )
  const [deleteLoading, setDeleteLoading] = useState<any[]>([])
  const [buttonClick, setButtonClick] = useState(false)
  const [deleteIds, setDeleteIds] = useState<any[]>([])
  const [onlyDelete, setOnlyDelete] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const dispatch = useDispatch()
  const { Option } = Select
      
  const [formData, setFormData] = useState(cloneDeep({
    firstName: profileInfoNoPendingData?.data?.firstName || [{ value: '' }],
    middleName: profileInfoNoPendingData?.data?.middleName || [{ value: '' }],
    lastName: profileInfoNoPendingData?.data?.lastName || [{ value: '' }],
    phoneNumber: profileInfoNoPendingData?.data?.phoneNumber || [{ value: '' }],
    city: profileInfoNoPendingData?.data?.city || [{ value: '' }],
    email: profileInfoNoPendingData?.data?.email || [{ value: '' }],
  }))
  

  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: any,
    index: number,
  ) => {
    let { value } = event_.target
    if (stateName == 'phoneNumber') {
      value = phoneFormatter(value)
    }
    setFormData((prev: any) => {
      const update = { ...prev }
      update[stateName][index] = {
        ...update[stateName][index],
        value: value,
      }
      !isChanged && setIsChanged(true)
      return update
    })
  }

  const handleChangeSelect = (event: string, index: number) => {
    setSelectState((prev: any) => {
      const update = [...prev]
      update[index].value = event
      setIsChanged(true)
      return update
    })
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDateOfBirth(dateString)
    setIsChanged(true)
  }

  function dateFormatter(date: string) {
    const data = date.split('-')
    return data[2].length == 4 ? date : `${data[1]}-${data[2]}-${data[0]}`
  }

  const handleClick = () => {
    setButtonClick(true)
    // if (onlyDelete && !isChanged) return setEditProfileState(false)
    if (
      formData.middleName.every((item) => !item.value || (item.value && middleNameRegex.test(item.value))) &&
      formData.phoneNumber.every((item) => !item.value || (item.value && usPhoneRegex.test(item.value))) &&
      formData.lastName.every((item) => item.value && lastNameRegex.test(item.value)) &&
      selectState.every((item) => state.map((it) => it.name).includes(item.value)) &&
      formData.firstName.every((item) => item.value && nameRegex.test(item.value)) &&
      formData.email.every((item) => item.value && emailRegex.test(item.value)) &&
      formData.city.every((item) => item.value && cityRegex.test(item.value)) &&
      dateOfBirth
    ) {
      const body = {
        phoneNumber: formData.phoneNumber.map((item: any) => ({value: item.value,id: item?.id,delete: item?.delete,})).filter((item) => item.value !== '() -' || usPhoneRegex.test(item.value)),
        email: formData.email.filter((item) => item.value && item.value.toLowerCase()),
        firstName: [...formData.firstName.filter((item) => item.value), ...deleteIds],
        middleName: formData.middleName.filter((item) => item.value),
        lastName: formData.lastName.filter((item) => item.value),
        birth_date: dateFormatter(dateOfBirth as string),
        city: formData.city.filter((item) => item.value),
        state: selectState.filter((item) => item.value),
      }
      dispatch(ProfileEditInfo(body) as any)
    }
  }

  const handleField = (
    fieldName: KeyType | 'state',
    isToRemove?: boolean,
    index?: number,
    body?: DeleteProfileBody,
  ) => {
    // if (body?.id) {
    //   setDeleteLoading((prev) => [...prev, body.id])
    //   dispatch(DeleteProfileField({ id: body.id }, fieldName) as any)
    //   setOnlyDelete(true)
    // } else
    if (isToRemove) {
      setOnlyDelete(true)

      if (fieldName === 'state') {
        setSelectState((prev) => {
          if (index && prev[index].id) {
            setDeleteIds((data: any) => [...data, { ...prev[index], delete: true }])
          }
          return prev.filter((_, idx) => idx !== index)
        })
      } else {
        setFormData((prev) => {
          if (index && prev[fieldName][index].id) {
            setDeleteIds((data: any) => [...data, { ...prev[fieldName][index], delete: true }])
          }
          return {
            ...prev,
            [fieldName]: prev[fieldName].filter((_, idx) => idx !== index),
          }
        })
      }
    } else {
      fieldName == 'state'
        ? selectState.length < 5
          ? setSelectState((prev: any) => [...prev, { value: '' }])
          : toast.info('Maximum 5 Fields Allowed', { toastId: 'toastify' })
        : formData[fieldName].length < 5
        ? setFormData((prev: any) => {
            const update = { ...prev }
            update[fieldName] = [...update[fieldName], { value: '' }]
            return update
          })
        : toast.info('Maximum 5 Fields Allowed', { toastId: 'toastify' })
    }
  }

  const disableFutureDates = (current: Dayjs) => {
    // Disable dates after today
    return current && current.isAfter(dayjs().endOf('day'))
  }

  useEffect(() => {
    if (profileEditStatus !== 'pending') {
      setFormData(cloneDeep({
        firstName: profileInfoNoPendingData?.data?.firstName || [{ value: '' }],
        middleName: profileInfoNoPendingData?.data?.middleName || [{ value: '' }],
        lastName: profileInfoNoPendingData?.data?.lastName || [{ value: '' }],
        phoneNumber: profileInfoNoPendingData?.data?.phoneNumber || [{ value: '' }],
        city: profileInfoNoPendingData?.data?.city || [{ value: '' }],
        email: profileInfoNoPendingData?.data?.email || [{ value: '' }],
      }))
      setSelectState(profileInfoNoPendingData?.data?.state || [{ value: '' }])
    }
  }, [profileEditStatus])


  const changeDateFormat = (date: string) => {
    const data = date.split('-')
    return data[0].length == 4 ? date : [data[2], data[0], data[1]].join('-')
  }

  return (
    <>
      <div className='profile-edit-new'>
        <div className='column-fields'>
          <div>
            <label>First Name:</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.firstName.every((item) => item.value && nameRegex.test(item.value)) &&
                    '30px',
                } as any
              }
            >
              {formData.firstName.map((item, index) => (
                <div key={index} className='input-box'>
                  <Input
                    value={item.value}
                    onChange={(e) => handleChange(e, 'firstName', index)}
                    placeholder='First name'
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('firstName', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                      } as any
                    }
                  />

                  {buttonClick && (!item.value || !nameRegex.test(item.value)) && (
                    <p className='error-message message-active'>*Please enter a valid first name</p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('firstName')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>Middle Name:</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.middleName.every(
                      (item) => item.value && lastNameRegex.test(item.value),
                    ) &&
                    '30px',
                } as any
              }
            >
              {formData.middleName.map((item, index) => (
                <div key={index} className='input-box'>
                  <Input
                    key={index}
                    value={item?.value}
                    onChange={(e) => handleChange(e, 'middleName', index)}
                    placeholder='Middle name'
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('middleName', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                      } as any
                    }
                  />
                  {buttonClick && item.value && !middleNameRegex.test(item.value) && (
                    <p className='error-message message-active'>
                      *Please enter a valid middle name
                    </p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('middleName')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>Last Name:</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.lastName.every(
                      (item) => item.value && lastNameRegex.test(item.value),
                    ) &&
                    '30px',
                } as any
              }
            >
              {formData.lastName.map((item, index) => (
                <div key={index} className='input-box'>
                  <Input
                    key={index}
                    onChange={(e) => handleChange(e, 'lastName', index)}
                    placeholder='Last name'
                    value={item?.value}
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('lastName', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                      } as any
                    }
                  />
                  {buttonClick && (!item.value || !lastNameRegex.test(item.value)) && (
                    <p className='error-message message-active'>*Please enter a valid last name</p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('lastName')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>Email Address:</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.email.every((item) => item.value && emailRegex.test(item.value)) &&
                    '30px',
                } as any
              }
            >
              {formData.email.map((item, index) => (
                <div key={index} className='input-box'>
                  <Input
                    key={index}
                    disabled={index == 0}
                    onChange={(e) => handleChange(e, 'email', index)}
                    placeholder='Email Address'
                    value={item?.value}
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('email', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                      } as any
                    }
                  />
                  {buttonClick && (!item.value || !emailRegex.test(item.value)) && (
                    <p className='error-message message-active'>
                      *Please enter a valid Email Address
                    </p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('email')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>Phone Number</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.phoneNumber.every(
                      (item) => item.value && usPhoneRegex.test(item.value),
                    ) &&
                    '30px',
                } as any
              }
            >
              {formData.phoneNumber.map((item, index) => (
                <div key={index} className='input-box'>
                  <Input
                    key={index}
                    value={item?.value}
                    onChange={(e) => handleChange(e, 'phoneNumber', index)}
                    placeholder='Phone Number'
                    type={'text'}
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('phoneNumber', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space':
                          buttonClick && (!item.value || !usPhoneRegex.test(item.value))
                            ? '16px'
                            : 0,
                      } as any
                    }
                  />
                  {buttonClick && !usPhoneRegex.test(item.value) && (
                    <p className='error-message message-active'>
                      *Please enter a valid phone number
                    </p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('phoneNumber')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>City:</label>
            <div
              className='input-group '
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !formData.city.every((item) => item.value && cityRegex.test(item.value)) &&
                    '30px',
                } as any
              }
            >
              {formData.city.map((item, index) => (
                <div key={`${index}x`} className='input-box'>
                  <Input
                    key={index}
                    onChange={(e) => handleChange(e, 'city', index)}
                    placeholder='City'
                    value={item?.value}
                    addonAfter={
                      index &&
                      (deleteLoading.includes(item.id) ? (
                        <CircularProgress size={20} />
                      ) : (
                        <CloseOutlined
                          className='close-icon'
                          onClick={() => handleField('city', true, index, item)}
                        />
                      ))
                    }
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                      } as any
                    }
                  />
                  {buttonClick && (!item.value || !cityRegex.test(item.value)) && (
                    <p className='error-message message-active'>*Please enter a valid city</p>
                  )}
                </div>
              ))}
              <button className='add-field' onClick={() => handleField('city')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>State:</label>
            <div
              className='input-group'
              style={
                {
                  '--row-gap':
                    buttonClick &&
                    !selectState.every((item) => state.map((it) => it.name).includes(item.value)) &&
                    '30px',
                } as any
              }
            >
              {selectState.map((item, index) => (
                <div key={`${index}y`} className='select-box '>
                  {!!index &&
                    (deleteLoading.includes(item.id) ? (
                      <div className='close-icon d-flex'>
                        <CircularProgress size={20} className='m-auto' />
                      </div>
                    ) : (
                      <CloseOutlined
                        className='close-icon'
                        onClick={() => handleField('state', true, index, item)}
                      />
                    ))}

                  <Select
                    key={index}
                    value={item?.value || 'Select State'}
                    className='w-100'
                    suffixIcon={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='21'
                        height='21'
                        viewBox='0 0 21 21'
                        fill='none'
                      >
                        <path
                          d='M5.93945 7.51904L10.9395 12.519L15.9395 7.51904'
                          stroke='#667085'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    }
                    onChange={(e) => handleChangeSelect(e, index)}
                    style={
                      {
                        '--error-space': buttonClick && !item.value ? '16px' : 0,
                        '--svg-padding': index && '30px',
                      } as any
                    }
                  >
                    {state.map(({ name }) => (
                      <>
                        <Option value={name}>{name}</Option>
                      </>
                    ))}
                  </Select>
                  {buttonClick && !item.value && (
                    <p className='error-message message-active' style={{ marginTop: '-17px' }}>
                      *Please enter a valid state
                    </p>
                  )}
                </div>
              ))}

              <button className='add-field' onClick={() => handleField('state')}>
                +
              </button>
            </div>
          </div>

          <div>
            <label>Date of birth</label>
            <div
              className='select-box'
              style={
                {
                  '--row-gap': !dateOfBirth && buttonClick && '30px',
                } as any
              }
            >
              <DatePicker
                value={
                  dayjs(
                    changeDateFormat(dateOfBirth as string) ||
                      profileInfoNoPendingData?.data?.birthDate,
                    'YYYY-MM-DD',
                  ) || null
                }
                onChange={onChangeDate}
                className='w-100 h-100'
                placeholder='Date of birth'
                disabledDate={disableFutureDates}
                format={'MM-DD-YYYY'}
                style={{ '--error-space': !dateOfBirth && buttonClick ? '16px' : 0 } as any}
              />
              {!dateOfBirth && buttonClick && (
                <p className='error-message message-active'>*Please enter a valid date of birth</p>
              )}
            </div>
          </div>
        </div>

        <div className='profile-edit'>
          <div className='button-box-bottom'>
            <div className='button-box second-btn'>
              <Button onClick={() => setEditProfileState(false)}>Cancel</Button>
            </div>
            <div className='button-box'>
              <Button onClick={handleClick} disabled={!isChanged && !onlyDelete}>
                {profileEditStatus === 'pending' ? <Spinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewProfileEdit
