import InfoSection from './InfoSection'
import InnerPage from '../InnerPage'
import Search from './Search'
import Issues from './Issues'
import Deals from './Deals'
import Help from './Help'

import './style.scss'

export default function LandingPage() {
  return (
    <div>
      <InnerPage isLandingPage={true}>
        <div className='d-grid gap-3 grid-custom-columns'>
          <div className='hero-head'>
            <h1 className='heading'>
              Claim this Special offer available only to <span>Aurora</span> associates.
            </h1>
            <p>Discover for free which websites are sharing your information.</p>
          </div>
          <div className='hero-head'>
            <img
              src={require('../../assets/images/landingPage/Infography.svg').default}
              alt='hero image'
            />
          </div>
        </div>
        <div className='search'>
          <Search />
        </div>
        <div>
          <InfoSection />
        </div>
        <div>
          <Issues />
        </div>
        <div>
          <Help />
        </div>
        <div>
          <Deals />
        </div>
        <div className='manage-height' />
      </InnerPage>
    </div>
  )
}
