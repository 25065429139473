import { PrivacyData } from './data'
import { FC } from 'react'

import './styles.scss'

const PrivacyPolice: FC = () => {
  return (
    <>
      <div className='privacy-police'>
        <div className='top-section'>
          <h1>Privacy Policy</h1>
        </div>
        <div className='container'>
          {PrivacyData.map(({ text, detail, form, heading, line }) => (
            <>
              <div className='point'>
                {heading && <h5>{heading}</h5>}
                <p>{text}</p>
                {line && <p>{line}</p>}
                {detail &&
                  detail.map((item, index) => (
                    <p key={index} className='ms-4'>
                      {item}
                    </p>
                  ))}
                {form && <p>{form}</p>}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default PrivacyPolice
