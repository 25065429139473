/* eslint-disable no-useless-escape */
/* eslint-disable quotes */

import { InnerPageInterface } from './interfaces'
import Footer from './components/Footer'
import Header from './components/Header'
import { FC } from 'react'

import './styles.scss'

const InnerPage: FC<InnerPageInterface> = ({ children, isLandingPage, isSlugLanding }) => {
  return (
    <div className='website-container'>
      <Header isLandingPage={isLandingPage} isSlugLanding={isSlugLanding} />
      <div>{children}</div>
      {!['/sign-in', '/sign-up', '/create-password', '/^/business/+d+$/'].includes(
        window.location.pathname,
      ) ? (
        <Footer />
      ) : (
        <div className='footer bg-transparent'></div>
      )}
    </div>
  )
}

export default InnerPage
