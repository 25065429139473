import { ProfileEditSectionInterface } from '../ProfileView/interface'
// import ProfileEditFields from './ProfileEditFileds'
import ProfileTopSection from '../ProfileTopSection'
import NewProfileEdit from './NewProfileEdit'
import { FC } from 'react'

const ProfileEditSection: FC<ProfileEditSectionInterface> = ({
  profileEditInfoAction,
  profileEditStatus,
  profileEditData,
  profileInfoNoPendingData,
  setEditProfileState,
  editProfileState,
}) => {
  return (
    <>
      <div className='profile'>
        <ProfileTopSection
          type='edit'
          title='Profile'
          description='Updating your details for the first time will trigger a new search immediately, but additional edits will be used in the new search cycle only.'
          editProfileState={editProfileState}
        />
        <NewProfileEdit
          profileEditStatus={profileEditStatus}
          profileEditInfoAction={profileEditInfoAction}
          profileEditData={profileEditData}
          profileInfoNoPendingData={profileInfoNoPendingData}
          setEditProfileState={setEditProfileState}
        />
      </div>
    </>
  )
}

export default ProfileEditSection
