import { confirmForgotPassword, confirmForgotPasswordReset, forgotPassword, forgotPasswordReset } from '../../store/actions/AuthActions'
import { ConfirmForgotPasswordInterface, ForgotPasswordInterface } from '../../store/actions/interfaces'
import { CombineInterface } from '../../store/reducers/interfaces'
import { ForgotPasswordInterfaces } from './interfaces'
import ForgotPasswordFields from './ForgotPassword'
import { FC, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import '../Login/SignUp/styles.scss'

const ForgotPassword: FC<ForgotPasswordInterfaces> = ({
  authReducer: {
    forgotPasswordStatus,
    confirmPasswordStatus,
    confirmPasswordData,
    forgotPasswordData,
  },
  forgotPasswordAction,
  forgotPasswordResetAction,
  confirmForgotPasswordAction,
  confirmForgotPasswordResetAction,
}) => {
  const [redirectPart, setRedirectPart] = useState(false)

  useEffect(() => {
    return () => {
      forgotPasswordResetAction()
      confirmForgotPasswordResetAction()
      setRedirectPart(false)
    }
  }, [])
  
  return (
    <>
      <div className='signIn-section sign-up-section'>
        <div className='sign-in-box sign-up-box'>
          <ForgotPasswordFields
            forgotPasswordStatus={forgotPasswordStatus}
            forgotPasswordAction={forgotPasswordAction}
            confirmPasswordStatus={confirmPasswordStatus}
            confirmForgotPasswordAction={confirmForgotPasswordAction}
            confirmPasswordData={confirmPasswordData?.message || ''}
            forgotPasswordData={forgotPasswordData?.message || ''}
            redirectPart={redirectPart}
            setRedirectPart={setRedirectPart}
          />
        </div>
      </div>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  forgotPasswordAction: (body: ForgotPasswordInterface) => dispatch(forgotPassword(body)),
  forgotPasswordResetAction: () => dispatch(forgotPasswordReset()),
  confirmForgotPasswordResetAction: () => dispatch(confirmForgotPasswordReset()),
  confirmForgotPasswordAction: (body: ConfirmForgotPasswordInterface) =>
    dispatch(confirmForgotPassword(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ForgotPassword)
