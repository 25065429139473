import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers'

const devStage = process.env.NODE_ENV === 'development'

export default devStage
  ? createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
  : createStore(rootReducer, applyMiddleware(thunk))
