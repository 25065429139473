import PrivacyPoliceSection from './PrivacyPolicy'
import InnerPage from '../InnerPage'
import { FC } from 'react'

import './styles.scss'

const PrivacyPolice: FC = () => {
  return (
    <>
      <InnerPage>
        <PrivacyPoliceSection />
      </InnerPage>
    </>
  )
}

export default PrivacyPolice
