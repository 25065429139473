import { ProfileEditInfoAction, NewProfileInfoNoPendingAction } from '../../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../../store/reducers/interfaces'
import { ProfileEditBody } from '../../../../store/actions/interfaces'
import ProfileEditSection from '../ProfileEditSection/index'
import React, { FC, useState, useEffect } from 'react'
import ProfileViewSection from './ProfileViewSection'
import { ProfileViewInterface } from './interface'
import Spinner from '../../../../core-ui/Spinner'
import { useNavigate } from 'react-router-dom'
import NewProfileView from './NewProfileView'
import ProfileNavbar from '../ProfileNavbar'
import InnerPage from '../../../InnerPage'
import { connect } from 'react-redux'

import './styles.scss'

const ProfileView: FC<ProfileViewInterface> = ({
  profileReducer: {
    profileInfoNoPendingData,
    profileInfoNoPendingStatus,
    profileEditStatus,
    profileEditData,
  },
  NewProfileInfoNoPendingAction,
  profileEditInfoAction,
}) => {
  const [editProfileState, setEditProfileState] = useState(false)

  const navigate = useNavigate()

  // useEffect(() => {
  //   const payment = localStorage.getItem('payment')
  //   if (!payment) {
  //     navigate('/subscribe')
  //   }
  // }, [])

  useEffect(() => {
    if (profileEditStatus === 'success') {
      setEditProfileState(false)
      NewProfileInfoNoPendingAction()
    }
  }, [profileEditStatus])

  useEffect(() => {
    if (
      profileInfoNoPendingData?.data &&
      !Object.values(profileInfoNoPendingData?.data).length &&
      profileInfoNoPendingStatus === 'success'
    ) {
      navigate('/profile')
    }
  }, [profileInfoNoPendingData, profileInfoNoPendingStatus])
  const navigator = () => navigate('/profile')

  return (
    <>
      <InnerPage>
        {profileInfoNoPendingData?.data?.firstName?.length ? (
          <div className='profile-section'>
            <div className='container'>
              <div className='profile-section-child'>
                <ProfileNavbar />
                {editProfileState ? (
                  <ProfileEditSection
                    profileEditInfoAction={profileEditInfoAction}
                    profileEditStatus={profileEditStatus}
                    profileEditData={profileEditData?.error || ''}
                    profileInfoNoPendingData={profileInfoNoPendingData}
                    setEditProfileState={setEditProfileState}
                    editProfileState={editProfileState}
                  />
                ) : (
                  <>
                    {
                      <NewProfileView
                        profileInfoNoPendingStatus={profileInfoNoPendingStatus}
                        profileInfoNoPendingData={profileInfoNoPendingData}
                        setEditProfileState={setEditProfileState}
                        editProfileState={editProfileState}
                      />
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <>{null}{navigator()}</>
        )}
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  NewProfileInfoNoPendingAction: () => dispatch(NewProfileInfoNoPendingAction()),
  profileEditInfoAction: (body: ProfileEditBody) => dispatch(ProfileEditInfoAction(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ProfileView)
