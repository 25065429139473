// eslint-disable-next-line no-useless-escape
export const addressRegex = new RegExp(/^\d+\s+[a-zA-Z0-9\s#]+,\s*[a-zA-Z\s]+,\s*[a-zA-Z]{2}\s+\d{5}$/)
export const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!.@#$%^&*])(?=.{8,})')
// eslint-disable-next-line no-useless-escape
export const emailRegex = new RegExp(/^[\w+]+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/)
export const usPhoneRegex = new RegExp(/^(\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4})?$/)
export const companyRegex = new RegExp(/^[a-zA-Z0-9\s&'.,-]{3,}$/)
export const pocNumberRegex = RegExp(/^\+\d{1,3}\s\d{3}\s\d{6}$/)
export const middleNameRegex = new RegExp(/^([a-zA-Z\s.]+)?$/)
export const lastNameRegex = new RegExp(/^[a-zA-Z]{1,}$/)
export const messageRegex = new RegExp(/^[\S\s]{10,200}$/)
export const nameRegex = new RegExp(/^[a-zA-Z]{3,}$/)
export const cityRegex = new RegExp(/^[a-zA-Z\s]{2,}$/)
export const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/
export const phoneRegex = new RegExp(/^(\d{10})?$/)
export const estimatedUserRegex = RegExp(/[1-8]/)


export const formValidator = (data: string, validationRegex: RegExp) => validationRegex.test(data)

export const validatePassword = (password: string) => {
  // return passwordRegex.test(password);
  if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) return false
  if (!/[a-z]/.test(password)) return false
  if (!/[A-Z]/.test(password)) return false
  if (!/\d/.test(password)) return false
  if (password.length < 8) return false

  return true
}

