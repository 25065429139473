import  { FC } from 'react'

import TermsSection from './TermsOfUse'
import InnerPage from '../InnerPage'

import './styles.scss'

const Terms: FC = () => {
  return (
    <>
      <InnerPage>
        <TermsSection />
      </InnerPage>
    </>
  )
}

export default Terms
