import { useNavigate } from 'react-router-dom'

import './styles.scss'

const ProfileNavbar = () => {
  const navigate = useNavigate()
  
  const handleClick = (val: string) => {
    navigate(`/profile-${val}`)
  }

  return (
    <>
      <div className='profile-navbar'>
        <h1>Settings</h1>
        <div className='menu'>
          <div
            className={
              window.location.pathname === '/profile-view'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('view')}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
            >
              <path
                d='M3 20.019C5.33579 17.5416 8.50702 16.019 12 16.019C15.493 16.019 18.6642 17.5416 21 20.019M16.5 7.51904C16.5 10.0043 14.4853 12.019 12 12.019C9.51472 12.019 7.5 10.0043 7.5 7.51904C7.5 5.03376 9.51472 3.01904 12 3.01904C14.4853 3.01904 16.5 5.03376 16.5 7.51904Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Profile</p>
          </div>
          {/* <div
            className={
              window.location.pathname === '/profile-plan'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('plan')}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
            >
              <path
                d='M8.5 14.6857C8.5 15.9744 9.54467 17.019 10.8333 17.019H13C14.3807 17.019 15.5 15.8998 15.5 14.519C15.5 13.1383 14.3807 12.019 13 12.019H11C9.61929 12.019 8.5 10.8998 8.5 9.51904C8.5 8.13833 9.61929 7.01904 11 7.01904H13.1667C14.4553 7.01904 15.5 8.06371 15.5 9.35238M12 5.51904V7.01904M12 17.019V18.519M22 12.019C22 17.5419 17.5228 22.019 12 22.019C6.47715 22.019 2 17.5419 2 12.019C2 6.4962 6.47715 2.01904 12 2.01904C17.5228 2.01904 22 6.4962 22 12.019Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Plan & Billing</p>
          </div> */}
          <div
            className={
              window.location.pathname === '/profile-security'
                ? 'menu-item menu-item-active'
                : 'menu-item'
            }
            onClick={() => handleClick('security')}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
            >
              <path
                d='M17 9.01898C17 8.50716 16.8047 7.99534 16.4142 7.60483C16.0237 7.21431 15.5118 7.01904 15 7.01904M15 15.019C18.3137 15.019 21 12.3328 21 9.01904C21 5.70533 18.3137 3.01904 15 3.01904C11.6863 3.01904 9 5.70533 9 9.01904C9 9.29272 9.01832 9.56212 9.05381 9.82608C9.11218 10.2602 9.14136 10.4773 9.12172 10.6146C9.10125 10.7577 9.0752 10.8348 9.00469 10.9609C8.937 11.082 8.81771 11.2013 8.57913 11.4399L3.46863 16.5504C3.29568 16.7234 3.2092 16.8098 3.14736 16.9108C3.09253 17.0002 3.05213 17.0978 3.02763 17.1998C3 17.3149 3 17.4372 3 17.6818V19.419C3 19.9791 3 20.2591 3.10899 20.473C3.20487 20.6612 3.35785 20.8142 3.54601 20.91C3.75992 21.019 4.03995 21.019 4.6 21.019H7V19.019H9V17.019H11L12.5791 15.4399C12.8177 15.2013 12.937 15.082 13.0581 15.0144C13.1843 14.9438 13.2613 14.9178 13.4044 14.8973C13.5417 14.8777 13.7588 14.9069 14.193 14.9652C14.4569 15.0007 14.7263 15.019 15 15.019Z'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p>Security</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileNavbar
