import { FC, useState } from 'react'
import { Button } from 'antd'

import SuccessMessage from '../../../SuccessMessage/Index'
import Spinner from '../../../../core-ui/Spinner'
import { PaymentInterface } from './interfaces'

import './styles.scss'

const Payment: FC<PaymentInterface> = ({
  profileInfoNoPendingData,
  upgradeSubscription,
  subscriptionUpgradeStatus,
  profileInfoNoPendingStatus,
  subscriptionUpgradeData,
}) => {
  const [handleState, setHandleState] = useState(profileInfoNoPendingData?.data?.subscription?.subscriptionType)
  
  const handleClick = (type: string) => {
    setHandleState(type)
  }

  const handleUpgradeClick = () => {
    const body = {
      subscriptionType: handleState || '',
    }
    upgradeSubscription(body)
  }

  return (
    <>
      {subscriptionUpgradeData && subscriptionUpgradeStatus === 'success' ? (
        <div
          style={{
            transition: '.3s all ease',
          }}
        >
          <div className='mt-4'>
            <SuccessMessage message={subscriptionUpgradeData} />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='payment-box'>
        <h1>Find the Perfect Plan for You</h1>
        <div className='payment-section'>
          <div className='handle-size'>
            <div
              onClick={() => handleClick('monthly')}
              className={handleState === 'monthly' ? 'handle-part active-part' : 'handle-part'}
            >
              <div className='btn-section'>
                {handleState === 'monthly' ? (
                  <div className='active-button'>
                    {profileInfoNoPendingData?.data.subscription.status}
                  </div>
                ) : (
                  <div className='disable-button'>
                    {profileInfoNoPendingData?.data.subscription.status}
                  </div>
                )}
              </div>
              <div className='box'>
                <h6>
                  $129<span>/month</span>
                </h6>
                <p>
                  Lorem ipsum dolor sit amet consectetur. <br /> Quam tellus semper eget proin a{' '}
                  <br /> elementum consequat.
                </p>
              </div>
            </div>
            <div
              onClick={() => handleClick('yearly')}
              className={handleState === 'yearly' ? 'handle-part active-part' : 'handle-part'}
            >
              <div className='btn-section'>
                {handleState === 'yearly' ? (
                  <div className='active-button'>
                    {profileInfoNoPendingData?.data.subscription.status}
                  </div>
                ) : (
                  <div className='disable-button'>
                    {profileInfoNoPendingData?.data.subscription.status}
                  </div>
                )}
              </div>
              <div className='box'>
                <div className='payment-button'>
                  <h6>
                    $1200<span>/year</span>
                  </h6>
                  <div className=''>Save 22.47%</div>
                </div>
                <p>
                  Lorem ipsum dolor sit amet consectetur. <br /> Quam tellus semper eget proin a{' '}
                  <br /> elementum consequat.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='upgrade-button'>
          <Button
            disabled={
              profileInfoNoPendingData?.data.subscription.subscriptionType === handleState
                ? true
                : false
            }
            onClick={handleUpgradeClick}
          >
            {subscriptionUpgradeStatus === 'pending' || profileInfoNoPendingStatus === 'pending' ? (
              <Spinner />
            ) : (
              'Upgrade'
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default Payment
