import { InformationInternetInterface,Data } from './interfaces'
import { phoneFormatter } from '../ContactUs/ContactSection'
import { Button, DatePicker, Input, Select } from 'antd'
import React, { useState, FC, useEffect } from 'react'
import { emailRegex } from '../../utils/regex'
import { useNavigate } from 'react-router-dom'
import Spinner from '../../core-ui/Spinner'
import state from '../../assets/state'
import { toast } from 'react-toastify'

const { Option } = Select



const InformationInternet: FC<InformationInternetInterface> = ({
  searchAction,
  searchStatus,
  requestId,
  searchResetAction,
}) => {
  const [dateOfBirth, setDateOfBirth] = useState<string | string[]>('')
  const [selectState, setSelectState] = useState('')
  const [formData, setFormData] = useState<Data>({
    firstName: '',
    middleName: '',
    lastName: '',
    city: '',
    phoneNumber: '',
    email: '',
  })
  const navigate = useNavigate()

  const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
    let { value } = event_.target
    if (stateName == 'phoneNumber') {
      value = phoneFormatter(value)
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }
  const handleChangeSelect = (event: string) => {
    setSelectState(event)
  }

  const handleClick = (e: any) => {
    e.preventDefault()
    const body = {
      // eslint-disable-next-line camelcase
      first_name: formData.firstName,
      // eslint-disable-next-line camelcase
      middle_name: formData.middleName,
      // eslint-disable-next-line camelcase
      last_name: formData.lastName,
      // eslint-disable-next-line camelcase
      birth_date: dateOfBirth,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      city: formData.city,
      state: selectState,
    }
    !dateOfBirth
      ? toast.info('Required birth date', { toastId: 'dob' })
      : !selectState
      ? toast.info('Please Select State', { toastId: 'select' })
      : searchAction(body)
  }

  useEffect(() => {
    if (searchStatus === 'success' && requestId) {
      navigate(
        `/result?id=${requestId}&firstName=${formData.firstName}&lastName=${formData.lastName}&city=${formData.city}&state=${selectState}`,
      )
      searchResetAction()
    }
  }, [searchStatus])

  return (
    <>
      <div className='hero-engine'>
        <div className='container'>
          <div className='information'>
            <h1>Give our detection engine a spin!</h1>
            <p>
              We dig through millions of data points from national, state, and local public records.
              <br />
              See what’s out there about you.
            </p>
            {/* 
            <form onSubmit={handleClick}>
              <div className='search-box'>
                <div className='search-box-main'>
                  <div className='search-line-one'>
                    <div>
                      <label>First Name*</label>
                      <Input
                        name='firstName'
                        type='text'
                        required
                        onChange={(e) => handleChange(e, 'firstName')}
                        placeholder='e.g John'
                      />
                    </div>
                    <div>
                      <label>Middle Name</label>
                      <Input
                        name='middleName'
                        type='text'
                        onChange={(e) => handleChange(e, 'middleName')}
                        placeholder='e.g D.'
                      />
                    </div>
                    <div>
                      <label>Last Name*</label>
                      <Input
                        name='lastName'
                        type='text'
                        required
                        onChange={(e) => handleChange(e, 'lastName')}
                        placeholder='e.g Smith'
                      />
                    </div>

                    <div>
                      <label>Phone Number</label>
                      <Input
                        name='phone'
                        type='number'
                        value={formData.phoneNumber}
                        onChange={(e) => handleChange(e, 'phoneNumber')}
                        placeholder='e.g 111 222 3333'
                      />
                    </div>
                  </div>

                  <div className='search-line-one'>
                    <div>
                      <label>Email</label>
                      <Input
                        name='email'
                        type='email'
                        pattern={emailRegex.source}
                        onChange={(e) => handleChange(e, 'email')}
                        placeholder='e.g john@domain.com'
                      />
                    </div>
                    <div>
                      <label>Date of Birth*</label>
                      <DatePicker
                        name='dob'
                        type='text'
                        placeholder='Select DOB'
                        format={'MM-DD-YYYY'}
                        onChange={(_, date) => setDateOfBirth(date)}
                      />
                    </div>

                    <div>
                      <label>City*</label>
                      <Input
                        name='city'
                        required
                        type='text'
                        value={formData.city}
                        placeholder='Jersey City'
                        onChange={(e) => handleChange(e, 'city')}
                      />
                    </div>
                    <div>
                      <label>State*</label>
                      <Select
                        placeholder='California'
                        suffixIcon={
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='16'
                            height='9'
                            viewBox='0 0 16 9'
                            fill='none'
                          >
                            <path
                              d='M0.874023 0.857422L7.5567 7.5401C7.60548 7.59207 7.66439 7.6335 7.72981 7.66182C7.79522 7.69013 7.86574 7.70474 7.93702 7.70474C8.0083 7.70474 8.07882 7.69013 8.14423 7.66182C8.20964 7.6335 8.26856 7.59207 8.31733 7.5401L15 0.857422'
                              stroke='#002661'
                              strokeWidth='1.71429'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        }
                        onChange={(e) => handleChangeSelect(e)}
                      >
                        {state.map(({ name }) => (
                          <Option key={name} value={name}>
                            {name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <Button htmlType='submit'>
                  {searchStatus === 'pending' ? <Spinner /> : 'Search'}
                </Button>
              </div>
            </form>
            <p className='lock'>
              <img src={require('../../assets/images/home/lock.svg').default} /> We respect your
              privacy.
            </p> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default InformationInternet
