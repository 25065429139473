import { CombineInterface } from '../../../store/reducers/interfaces'
import { SignInInterface } from '../../../store/actions/interfaces'
import { signIn } from '../../../store/actions/AuthActions'
import { connect, useSelector } from 'react-redux'
import { useState, FC, useEffect } from 'react'
import { SignInInterfaces } from './interfaces'
import { useNavigate } from 'react-router'
import InnerPage from '../../InnerPage'
import SignInFields from './SignIn'

import '../SignUp/styles.scss'

const SignIn: FC<SignInInterfaces> = ({
  authReducer: { signInStatus, signInData },
  signInAction,
}) => {
  const { profileInfoNoPendingData } = useSelector((state: any) => state.profileReducer)
  const [formData, setFormData] = useState({email: '',password: ''})
  const login = localStorage.getItem('login')
  const navigate = useNavigate()

  useEffect(() => {
    if (profileInfoNoPendingData?.data?.profileId&&login=='true') return navigate('/')
  }, [profileInfoNoPendingData?.data?.profileId,login])

  return (
    <>
      <InnerPage>
        <div className='container'>
          <div className='signIn-section sign-up-section'>
            <div className='sign-in-box sign-up-box'>
              <SignInFields
                setFormData={setFormData}
                formData={formData}
                signInStatus={signInStatus}
                signInAction={signInAction}
                signInData={signInData}
              />
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signInAction: (body: SignInInterface) => dispatch(signIn(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(SignIn)
