import { FC } from 'react'

import { TermsData } from './data'

import './styles.scss'

const Terms: FC = () => {
  return (
    <>
      <div className='privacy-police'>
        <div className='top-section'>
          <h1>Terms and Conditions </h1>
        </div>
        <div className='container'>
          {TermsData.map(({ text, form, heading, line }, index) => (
            <div className='point' key={index}>
              {heading && <h5>{heading}</h5>}
              <p>{text}</p>
              {line && <p>{line}</p>}
              {form && <p>{form}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Terms
