import { AxiosResponse } from 'axios'

import { HTTP } from '../../utils/http'
import { LOGO } from '../constants'

export const getLogo = () => async (dispatch: (e: any) => void) => {
  try {
    const date = Date.now()
    const lastRun = localStorage.getItem('lastRun')
    const oneWeek = 7 * 24 * 60 * 60 * 1000
    if (lastRun) {
      const lastRunDate = new Date(parseInt(lastRun, 10))
      if (date - lastRunDate.getTime() < oneWeek) {
        return
      }
    }

    dispatch({ type: LOGO.PENDING })

    const response: AxiosResponse = await HTTP.get('api/data/sites-available')
    localStorage.setItem('lastRun', date.toString())
    localStorage.setItem('webLogos', JSON.stringify(response.data?.data?.sites))
    dispatch({ type: LOGO.SUCCESS, payload: response })
  } catch (error: any) {
    dispatch({ type: LOGO.ERROR, error: error })
  }
}
