import { resendForgotPasswordOtp } from '../../store/actions/AuthActions'
import { CombineInterface } from '../../store/reducers/interfaces'
import { emailRegex, validatePassword } from '../../utils/regex'
import { ForgotPasswordInterfacesFields } from './interfaces'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, FC, useEffect } from 'react'
import ChangePasswordFields from './ChangePassword'
import Spinner from '../../core-ui/Spinner'
import { useNavigate } from 'react-router'
import { Input, Button } from 'antd'

let passwordCheckOpened = false

const ForgotPasswordFields: FC<ForgotPasswordInterfacesFields> = ({
  forgotPasswordAction,
  forgotPasswordStatus,
  confirmPasswordStatus,
  confirmForgotPasswordAction,
  confirmPasswordData,
  forgotPasswordData,
  redirectPart,
  setRedirectPart,
}) => {
  const { resendForgetStatus } = useSelector((state: CombineInterface) => state.authReducer)
  const [formDataChangePassword, setFormDataChangePassword] = useState({
    code: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [buttonClickChange, setButtonClickChange] = useState(false)
  const [passwordState, setPasswordState] = useState(false)
  const [buttonClick, setButtonClick] = useState(false)
  const [errorState] = useState([{emailError: false,}])
  const [formData, setFormData] = useState({email: ''})
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangePassword = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    setButtonClickChange(false)
    const { value } = event_.target
    if (stateName === 'newPassword' && passwordState && validatePassword(event_.target.value)) {
      setPasswordState(false)
    } else if (
      stateName === 'newPassword' &&
      !validatePassword(event_.target.value) &&
      !passwordState &&
      passwordCheckOpened
    ) {
      setPasswordState(true)
    }
    setFormDataChangePassword((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleFocusValidation = (val: string, key: string, value: string) => {
    if (key === 'newPassword') {
      if (val === 'focus') {
        passwordCheckOpened = true
        if (!validatePassword(value)) {
          setPasswordState(true)
        }
      } else {
        setPasswordState(false)
      }
    }
  }

  const handleChange = (event_: React.ChangeEvent<HTMLInputElement>, stateName: string) => {
    const { value } = event_.target
    for (const error of errorState) {
      if (stateName === 'email' && !emailRegex.test(value.trim())) {
        error.emailError = true
      } else if (emailRegex.test(value.trim())) {
        error.emailError = false
      }
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleClick = async () => {
    if (forgotPasswordStatus !== 'success') {
      setButtonClick(true)

      if (!errorState[0].emailError && formData.email !== '') {
        forgotPasswordAction({
          email: formData.email,
        })
      }
    } else {
      setButtonClickChange(true)
      if (
        validatePassword(formDataChangePassword.newPassword) &&
        formDataChangePassword.confirmPassword === formDataChangePassword.newPassword &&
        formDataChangePassword.newPassword &&
        formData.email
      ) {
        const body = {
          email: formData.email,
          password: formDataChangePassword.newPassword,
          code: formDataChangePassword.code,
        }
        confirmForgotPasswordAction(body)
      }
    }
  }

  useEffect(() => {
    if (forgotPasswordStatus === 'success') {
      setTimeout(() => {
        setRedirectPart(true)
      }, 2000)
    }
  }, [forgotPasswordStatus])

  return (
    <>
      <style>
        {'a{color:#1155CC}button{margin-bottom:20px !important;}'}
      </style>
      <h1 style={{ fontWeight: 600 }}>
        {forgotPasswordStatus === 'success' && redirectPart
          ? 'Reset your password'
          : 'Forgot Password'}
      </h1>
      {forgotPasswordStatus === 'success' && redirectPart ? (
        <ChangePasswordFields
          passwordState={passwordState}
          formDataChangePassword={formDataChangePassword}
          handleChangePassword={handleChangePassword}
          handleFocusValidation={handleFocusValidation}
          buttonClick={buttonClickChange}
          confirmPasswordData={confirmPasswordData}
          confirmPasswordStatus={confirmPasswordStatus}
        />
      ) : (
        <>
          <div className='position-relative'>
            <label>Email Address*</label>
            <Input onChange={(e) => handleChange(e, 'email')} placeholder='Enter your email' />
            {(errorState[0].emailError || formData.email === '') && buttonClick ? (
              <p className='error-message message-active position-absolute start-0'>
                *Please enter a valid email address
              </p>
            ) : (
              <p className='error-message message-disable'></p>
            )}
          </div>
        </>
      )}
      {forgotPasswordStatus === 'success' && !redirectPart && (
        <p className='check-email check-active mb-0'>Please check your email</p>
      )}
      <>
        {forgotPasswordData && !redirectPart && forgotPasswordStatus !== 'pending' && (
          <p className='error-message message-active'>{forgotPasswordData}</p>
        )}
      </>
      <Button onClick={handleClick}>
        {forgotPasswordStatus === 'pending' || confirmPasswordStatus === 'pending' ? (
          <Spinner />
        ) : (
          'Reset Password'
        )}
      </Button>
      {forgotPasswordStatus === 'success' && redirectPart && (
        <>
          <p className='text-center'>
            Didn’t get the code?{' '}
            <a
              href='#'
              onClick={() => dispatch(resendForgotPasswordOtp({ email: formData.email }) as any)}
            >
              {resendForgetStatus == 'pending' ? <Spinner /> : 'Resend Code'}
            </a>
          </p>
          <p className='text-center'>
            Need assistance?{' '}
            <a href='#' onClick={() => navigate('/contact')}>
              Contact Us
            </a>
          </p>
        </>
      )}
    </>
  )
}

export default ForgotPasswordFields
