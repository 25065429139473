import { PasswordPopoverInterface } from './interfaces'
import { CheckOutlined } from '@ant-design/icons'
import { FC } from 'react'

const PasswordPopover: FC<PasswordPopoverInterface> = ({ password }) => {
  const containCharacterLength = () => {
    if (password.length >= 8) return true
    else return false
  }

  const containNumber = () => {
    if (password && /\d/.test(password)) return true
    else return false
  }

  const containUpper = () => {
    if (password && /[A-Z]/.test(password)) return true
    else return false
  }

  const containLower = () => {
    if (password && /[a-z]/.test(password)) return true
    else return false
  }

  const containCharacter = () => {
    if (password && /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) return true
    else return false
  }

  return (
    <div className={'password_popover_container'}>
      <div className={`password_validation_item ${containLower() ? 'active' : ''}`}>
        <CheckOutlined />
        <p>Password must contain a lower case letter</p>
      </div>
      <div className={`password_validation_item ${containUpper() ? 'active' : ''}`}>
        <CheckOutlined />
        <p>Password must contain an upper case letter</p>
      </div>
      <div className={`password_validation_item ${containNumber() ? 'active' : ''}`}>
        <CheckOutlined />
        <p>Password must contain a number</p>
      </div>
      <div className={`password_validation_item ${containCharacterLength() ? 'active' : ''}`}>
        <CheckOutlined />
        <p>Password must contain at least 8 characters</p>
      </div>
      <div className={`password_validation_item ${containCharacter() ? 'active' : ''}`}>
        <CheckOutlined />
        <p>Password must contain at least one special character</p>
      </div>
    </div>
  )
}

export default PasswordPopover
