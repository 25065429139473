import { AxiosError, AxiosResponse } from 'axios'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import { PROFILE, PROFILE_INFO_NO_PENDING, PROFILE_RESULTS_NO_PENDING, PROFILE_EDIT, DELETE_ACCOUNT, CANCEL_SUBSCRIPTION, RESUME_SUBSCRIPTION, SUB_PROFILE} from '../constants'
import { newProfileInfoNoPendingDataType, profileInfoNoPendingDataType } from '../reducers/interfaces'
import { DeleteProfileBody, NewProfileEditBody, ProfileBody, ProfileEditBody } from './interfaces'
import { HTTP } from '../../utils/http'

export const ProfileAction = (body: ProfileBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: PROFILE.PENDING })
    const response = await HTTP.post('api/profile', body)
    dispatch({ type: PROFILE.SUCCESS, payload: response.data })
  } catch (error: AxiosError | any) {
    toast.error(error?.response?.data?.error || error.message, { toastId: 'body' })

    dispatch({ type: PROFILE.ERROR, error: error })
  }
}

// this is for subProfile
// export const getSingleSubProfiles = () => async (dispatch: (event: any) => void) => {
//   try {
//     dispatch({ type: SUB_PROFILE.PENDING })
//     const response = await HTTP.get('api/sub_profile/cb9ce46eb4174eb58f32a408196a245c')
//     dispatch({ type: SUB_PROFILE.SUCCESS, payload: response.data })
//   } catch (error) {
//     dispatch({ type: SUB_PROFILE.ERROR, error: error })
//   }
// }
export const getSubProfiles = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUB_PROFILE.PENDING })
    const response = await HTTP.get('api/sub_profile')
    dispatch({ type: SUB_PROFILE.SUCCESS, payload: response.data })
  } catch (error: any) {
    toast.error(error?.response?.data?.error || error.message, { toastId: 'body' })
    dispatch({ type: SUB_PROFILE.ERROR, error: error })
  }
}

export const createSubProfile = (body: any) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUB_PROFILE.PENDING })
    const response = await HTTP.post('api/sub_profile', {
      subProfiles: body,
    })
    dispatch({ type: SUB_PROFILE.SUCCESS, payload: response.data })
    toast.success('Profile Created Successfully', { toastId: 'success' })
    dispatch(getSubProfiles())
  } catch (error: any) {
    dispatch({ type: SUB_PROFILE.ERROR, error: error })
    toast.error(error?.response?.data?.error || error.message, { toastId: 'createSubProfile' })
  }
}

export const deleteSubProfile = (body: any) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUB_PROFILE.PENDING })
    await HTTP.delete('api/sub_profile', body)
    dispatch({ type: SUB_PROFILE.DELETE, payload: { id: body.profileId } })
    toast.success('You have Deleted Profile Successfully', { toastId: 'deleteSubProfile' })
  } catch (error: any) {
    dispatch({ type: SUB_PROFILE.ERROR, error: error })
    toast.error(error?.response?.data?.error || error.message, { toastId: 'deleteSubProfile' })
  }
}

export const getSingleSubProfile = (id: string) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUB_PROFILE.PENDING })
    const response = await HTTP.get('api/sub_profile/sub-profile-data-history', { profileId: id })
    dispatch({ type: SUB_PROFILE.SUCCESS, payload: response.data })
  } catch (error: any) {
    dispatch({ type: SUB_PROFILE.ERROR, error: error })
    toast.error(error?.response?.data?.error || error.message, { toastId: 'getSingleSubProfile' })
  }
}

export const resetSubProfiles = () => ({
  type: SUB_PROFILE.RESET,
})

export const ContactReset = () => ({
  type: PROFILE.RESET,
})

// export const ProfileInfoNoPendingAction =
//   (redirect?: boolean) => async (dispatch: (event: any) => void) => {
//     try {
//       dispatch({ type: PROFILE_INFO_NO_PENDING.PENDING })
//       const response = (await HTTP.get(
//         'api/profile',
//       )) as AxiosResponse<profileInfoNoPendingDataType>
//       dispatch({ type: PROFILE_INFO_NO_PENDING.SUCCESS, payload: response.data })
//       if (response.data?.data?.subscription?.subscriptionType) {
//         localStorage.setItem('firstName', response?.data?.data?.firstName)
//         localStorage.setItem('lastName', response?.data?.data?.lastName)
//         localStorage.setItem('payment', 'true')
//       }
//       if (redirect) {
//         const parsed = queryString.parse(location.search)
//         if (!parsed.firstName) {
//           window.location.href = '/subscribe'
//         } else {
//           window.location.href = `/subscribe?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`
//         }
//       }
//     } catch (error) {
//       dispatch({ type: PROFILE_INFO_NO_PENDING.ERROR, error: error })
//     }
//   }

export const NewProfileInfoNoPendingAction = (redirect?: boolean) => async (dispatch: (event: any) => void) => {

    try {
      dispatch({ type: PROFILE_INFO_NO_PENDING.PENDING })
      const response = (await HTTP.get(
        'api/profile',
      )) as AxiosResponse<newProfileInfoNoPendingDataType>
      dispatch({ type: PROFILE_INFO_NO_PENDING.SUCCESS, payload: response.data })
      if (response.data?.data?.subscription?.subscriptionType) {
        localStorage.setItem('firstName', response?.data?.data?.firstName[0].value)
        localStorage.setItem('lastName', response?.data?.data?.lastName[0].value)
        localStorage.setItem('payment', 'true')
      }
      if (redirect) {
        const parsed = queryString.parse(location.search)
        // if (!parsed.firstName) {
        //   window.location.href = '/subscribe'
        // } else {
        //   window.location.href = `/subscribe?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`
        // }

        window.location.href = '/search-results'
      }
    } catch (error) {
      dispatch({ type: PROFILE_INFO_NO_PENDING.ERROR, error: error })
    }
  }

export const ProfileInfoNoPendingResultsAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.PENDING })
    const response = await HTTP.get('api/profile/data-history')
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: PROFILE_RESULTS_NO_PENDING.ERROR, error: error })
  }
}

export const ProfileEditInfoAction = (body: ProfileEditBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: PROFILE_EDIT.PENDING })
      const response = await HTTP.put('api/profile', body)
      dispatch({ type: PROFILE_EDIT.SUCCESS, payload: response.data })
      toast.success('Congrats..! Profile Updated Successfully')
    } catch (error: any) {
      dispatch({ type: PROFILE_EDIT.ERROR, error: error })
      toast.error(error?.response?.data?.error || error.message, { toastId: 'update' })
    }
  }

export const ProfileEditInfo = (body: NewProfileEditBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: PROFILE_EDIT.PENDING })
      const response = await HTTP.put('api/profile', body)
      dispatch({ type: PROFILE_EDIT.SUCCESS, payload: response.data })
    } catch (error: any) {
      toast.error(error?.response?.data?.error || error.message, { toastId: 'update' })
      dispatch({ type: PROFILE_EDIT.ERROR, error: error })
    }
  }

export const AccountDeleteAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: DELETE_ACCOUNT.PENDING })
    const response = await HTTP.delete('api/profile')
    dispatch({ type: DELETE_ACCOUNT.SUCCESS, payload: response.data })
    localStorage.clear()
  } catch (error) {
    dispatch({ type: DELETE_ACCOUNT.ERROR, error: error })
  }
}
export type fieldType = | 'firstName' | 'middleName' | 'lastName' | 'phoneNumber' | 'city' | 'email' | 'state'

export const DeleteProfileField = (body: DeleteProfileBody, fieldName: fieldType) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: PROFILE_EDIT.DEL_PENDING })

      await HTTP.delete('api/profile/profile-fields', body)
      dispatch({ type: PROFILE_EDIT.DELETE_ONE, deleteField: { id: body.id, fieldName } })
    } catch (error: any) {
      dispatch({ type: PROFILE_EDIT.ERROR, error: error })
      toast.error(error?.response?.data?.error || error.message, { toastId: 'update' })
    }
  }

export const cancelSubscription = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: CANCEL_SUBSCRIPTION.PENDING })
    const response = await HTTP.post('api/payment/subscription/pause', {})
    dispatch({ type: CANCEL_SUBSCRIPTION.SUCCESS, payload: response?.data })
  } catch (error) {
    dispatch({ type: CANCEL_SUBSCRIPTION.ERROR, error: error })
  }
}

export const resetSubscription = () => ({
  type: CANCEL_SUBSCRIPTION.RESET,
})

export const SubscriptionResumeAction = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: RESUME_SUBSCRIPTION.PENDING })
    const response = await HTTP.post('api/payment/subscription/resume', {})
    dispatch({ type: RESUME_SUBSCRIPTION.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: RESUME_SUBSCRIPTION.ERROR, error: error })
  }
}

export const SubscriptionResumeResetAction = () => ({
  type: RESUME_SUBSCRIPTION.RESET,
})
