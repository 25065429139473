import { useState } from 'react'

import '../Test/style.scss'

export default function ImageLoader({ src, className }: { src: string; className: string }) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <div
      className='load-image'
      style={
        {
          '--stop-animation': !isLoaded && 'infinite alternate',
          '--stop-absolute': !isLoaded && 'absolute',
          '--bg-image': !isLoaded ? `url(${require('../../assets/images/logo/small.png')})` : '',
        } as any
      }
    >
      <img
        src={src}
        alt='something went wrong'
        className={className}
        style={{
          visibility: !isLoaded ? 'hidden' : 'visible',
        }}
        loading='lazy'
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  )
}
