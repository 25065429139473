import { InputField } from './Input'
import { Button } from 'antd'

export default function Search() {
  return (
    <div className='search-form'>
      <InputField placeholder='e.g John Smith' title='Full Name*' />
      <InputField placeholder='e.g seattle, Washington' title='City, State*' />
      <Button>Search</Button>
    </div>
  )
}
