import { LogoReducerInterface } from './interfaces'
import { DispatchPayloadType } from '.'
import { LOGO } from '../constants'

const initialState: LogoReducerInterface = {
  logoData: [],
  logoStatus: '',
  message: '',
}

export const LogoReducer = (
  state = initialState,
  { type, error, payload }: DispatchPayloadType,
) => {
  switch (type) {
    case LOGO.PENDING: {
      return {
        ...state,
        logoStatus: 'pending',
        logoData: [],
        message: '',
      } as LogoReducerInterface
    }
    case LOGO.SUCCESS: {
      return {
        ...state,
        logoStatus: 'success',
        logoData: payload?.data?.data?.sites,
        message: payload?.data.message,
      } as LogoReducerInterface
    }
    case LOGO.ERROR: {
      return {
        ...state,
        logoStatus: 'error',
        message: error?.response?.data?.error,
      } as LogoReducerInterface
    }
    case LOGO.RESET: {
      return {
        ...state,
        logoData: [],
        logoStatus: '',
        message: '',
      } as LogoReducerInterface
    }
    default: {
      return {
        ...state,
      } as LogoReducerInterface
    }
  }
}
