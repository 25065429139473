import { ContactReducersInterface } from './interfaces'
import { DispatchPayloadType } from './index'
import { CONTACT } from '../constants'

const initialState: ContactReducersInterface = {
  contactStatus: '',
  contactData: null,
}

const contactReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): ContactReducersInterface => {
  switch (type) {
    case CONTACT.PENDING: {
      return {
        ...state,
        contactStatus: 'pending',
      } as ContactReducersInterface
    }
    case CONTACT.SUCCESS: {
      return {
        ...state,
        contactStatus: 'success',
        contactData: payload,
      } as ContactReducersInterface
    }
    case CONTACT.ERROR: {
      return {
        ...state,
        contactStatus: 'error',
        contactData: error?.response?.data || 'something went wrong',
      } as ContactReducersInterface
    }
    case CONTACT.RESET: {
      return {
        ...state,
        contactStatus: '',
      } as ContactReducersInterface
    }
    default:
      return state
  }
}

export default contactReducer
